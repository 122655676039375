* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

p {
  margin-bottom: unset !important;
  color: black !important;
}

.app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.custom-dropdown {
  position: absolute !important;
  right: 8px;
  top: 8px;
  width: 36px !important;
  height: 36px !important;
  padding: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50% !important;
}

.custom-dropdown svg {
  height: 100% !important;
}

.ant-dropdown-menu {
  min-width: 90px;
}